import React from 'react';
import { Layout } from '../../components/common';

const Conditions = () => {
  return (
    <Layout>
      <div>index</div>
    </Layout>
  );
};

export default Conditions;
